$display: none;

.login-trouble-component {
    background-color: #032b4b;
    height: 100vh;
    width: 100%;
  }

  .login-trouble-container {
    margin: 100px auto;
    background-color: #0e3654;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  }

  .captcha{
    display: $display !important;
  }