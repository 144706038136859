.otpContainer {
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    max-width: 350px;
    margin: 1rem auto 0;
    -ms-flex-pack: justify;
    justify-content: space-between;
    direction: ltr;
}

.otpInput {
    -webkit-appearance: none;
    padding: 0;
    border: 2px solid #d6dade;
    border-radius: 4px;
    text-align: center;
    font-size: 24px;
    caret-color: var(--vtmn-color_brand-digital);
    width: 48px;
    height: 48px;
}