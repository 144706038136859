// .site-layout {
//     padding: 0px 60px;
//     padding-top: 60px;
//     margin-top: 64;
//     height: auto;
// }

// .balance-info {
//     text-align: center;
// }

// .display-box {
//     padding-top: 6px;
//     padding-bottom: 6px;
//     border: 1px black solid;
// }

// .row-header {
//     font-size: 1.2em;
//     font-weight: bold;
//     text-align: center;
// }

// .plot-title {
//     font-weight: bold;
//     text-decoration: underline;
//     text-align: center;
// }

// th{
//     text-align: left !important;
//     font-weight: bold !important;
// }



.site-layout .site-layout-background {
  background: #fff;
}
[data-theme="dark"] .site-layout .site-layout-background {
  background: #141414;
}

.ant-collapse-header-text {
  font-size: 16px;
}
