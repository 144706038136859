#dcl-changelog .ant-card.ant-card-bordered {
    width: 80%;
    border-radius: 10px;
    margin: 0 auto;
    box-shadow: 10px 10px 5px #ddd, 0px 0px 5px #ddd;
    -moz-box-shadow: 10px 10px 5px #ddd, 0px 0px 5px #ddd;
    -webkit-box-shadow: 10px 10px 5px #ddd, 0px 0px 5px #ddd;
    -o-box-shadow: 10px 10px 5px #ddd, 0px 0px 5px #ddd;
}

#dcl-changelog .ant-row {
    display: block;
}

#dcl-changelog .ant-row h2 {
    font-weight: bold;
}

#dcl-changelog .ant-row:nth-child(2) {
    font-weight: 500;
}

#dcl-changelog .ant-row:nth-child(3) {
    margin: 5px 0 0 20px;
}