$primary-btn-bgColor: #19acb3;
$error-msg-color: #f5a623;
$display: none;

@mixin primary-input-config {
  background: none !important;
  border: 1px solid #155075;
  color: #155082;
  height: 45px;
  border-radius: 5px;
}
@mixin primary-btn-config {
  width: 100%;
  background-color: $primary-btn-bgColor !important;
  border: 2px solid $primary-btn-bgColor !important;
  border-radius: 5px;
  color: white !important;
  outline: none;
  font-size: 17px;
  font-weight: 500;
  padding: 10px;
  box-sizing: border-box;
  height: auto !important;
  margin-top: 10px;
  margin-bottom: 10px;
}

@mixin backend-info-container {
  //background-color: #003670;
  //padding: 10px 25px 10px 15px;
  //box-shadow: 0 2px 4px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  //border-radius: 7px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}
.login-component {
  background-color: #032b4b;
  height: 100vh;
  width: 100%;
}
.my-login-form,
.login-modal {
  background-color: #0e3654;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.backend-error {
  @include backend-info-container;
  p {
    color: $error-msg-color;
    margin-bottom: 0;
    margin-left: 10px;
  }

  span {
    color: $error-msg-color;
  }
}

.back-error-container {
  margin: 100px auto;
}
.my-login-form {
  h3 {
    text-align: center;
    color: white;
    margin-bottom: 25px;
    font-size: 20px;
    margin-top: 25px;
  }
  p {
    color: #70777d;
    margin-top: 10px;
  }
}

.ant-btn-primary {
  &:active,
  &:visited {
    background-color: #19acb3 !important;
    color: white !important;
    position: relative;
    top: 1px;
  }
  &:hover {
    background-color: #22d3dc !important;
    border: 1px solid #22d3dc !important;
  }
}

.primary-button {
  @include primary-btn-config;
  &:active,
  &:visited {
    background-color: #19acb3 !important;
    color: white !important;
    position: relative;
    top: 1px;
  }
  &[disabled] {
    background: none !important;
    border: 2px solid $primary-btn-bgColor !important;
    color: $primary-btn-bgColor !important;
  }
  &:hover {
    background-color: #22d3dc !important;
    border: 2px solid #22d3dc !important;
  }
}

.primary-input {
  .ant-input {
    @include primary-input-config;
    border: none;
    &[disabled] {
      @include primary-input-config;
    }
  }
}

.anticon {
  svg {
    color: #155082;
  }
}
.has-error,
.ant-form-explain {
  p {
    color: #f5a623 !important;
  }
}

.ant-form-explain,
.input-info {
  padding-left: 10px;
  display: flex;
  p {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 0;
    color: #9b9b9b;
  }
  p:last-child {
    margin-left: 10px;
  }
}

.has-error {
  .ant-input-affix-wrapper {
    .ant-input {
      border-color: #f5a623 !important;
    }
  }
}

.primary-button.ant-btn.disabled,
.primary-button.ant-btn[disabled] {
  background: none !important;
  border: 2px solid $primary-btn-bgColor !important;
  color: #1abdc1 !important;
}

.ant-modal-footer {
  border-top: none;
  background-color: #0e3654;
}

.ant-modal-footer .ant-btn-primary {
  background-color: #19acb3;
  border-color: #19acb3;
}

.ant-modal-content {
  background-color: #0e3654;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.captcha {
  display: $display !important;
}

.login-form-forgot {
  text-align: center;
  color: #70777d;
  display: block;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

img.dashboard-logo {
  margin: 60px;
  height: 100px;
}

h1.dashboard-welcome {
  font-size: 2em;
}
