.dcl-btn-toggle span {
  padding: 0 10px;
}

.dcl-toggled-content {
  padding: 10px;
  border-radius: 5px;
  background-color: #19bdc217;
  border: 1px solid #19bdc2;
  margin: 0 0 10px 5px;
}

.dcl-filter-item {
  padding-left: 8px;
  padding-right: 8px;
  margin-bottom: 0px;
}

.dcl-toggled-content .ant-switch-handle {
  height: 14px;
  left: 2px;
  top: 1px;
  width: 14px;
}

.dcl-toggled-content .ant-switch {
  min-width: 30px !important;
  height: 16px;
}

.dcl-toggled-content .ant-switch-checked .ant-switch-handle {
  left: calc(100% - 16px);
}

.ant-table-column-sorter-down.active svg {
  color: #1890ff;
}

.ant-table-column-sorter-up.active svg {
  color: #1890ff;
}

.dcl-toggled-content-filter .dcl-filter-item {
  margin-bottom: 10px;
}

.pop-confirm-p {
  color: rgba(0, 0, 0, 0.85);
}

@media (max-width: 575px) {
  .ant-form-item .ant-form-item-label {
    padding-bottom: 0px;
  }

  #dcl-edit-wallet-page .ant-col-sm-12 {
    flex: 0 0 48%;
  }

  #dcl-create-wallet-page .ant-col-sm-12 {
    flex: 0 0 48%;
  }
}

/** Ebanking page 
*/
.ant-space.dcl-Debit,
.ant-space.dcl-Y,
.ant-space.dcl-sell {
  background-color: #ed4040;
  padding: 10px;
  color: #fff;
  font-weight: 600;
  border-radius: 5px;
}

.ant-space.dcl-Virtual.Withdrawal {
  background-color: #fcaf17;
  padding: 5px;
  color: #fff;
  font-weight: 600;
  border-radius: 5px;
}

.ant-space.dcl-venue-binance {
  background-color: #f3ba2f;
  padding: 5px;
  color: #000;
  font-weight: 600;
  border-radius: 5px;
  width: 100%;
}

.ant-space.dcl-venue-kraken {
  background-color: #5848d5;
  padding: 5px;
  color: #fff;
  font-weight: 600;
  border-radius: 5px;
  width: 100%;
}

.ant-space.dcl-venue-bitfinex2 {
  background-color: #16b157;
  padding: 5px;
  color: #fff;
  font-weight: 600;
  border-radius: 5px;
  width: 100%;
}

.ant-space.dcl-venue-kucoin {
  background-color: #01bc8d;
  padding: 5px;
  color: #fff;
  font-weight: 600;
  border-radius: 5px;
  width: 100%;
}

.ant-space.dcl-venue-mexc {
  background-color: #1877f2;
  padding: 5px;
  color: #fff;
  font-weight: 600;
  border-radius: 5px;
  width: 100%;
}

.ant-space.dcl-venue-gateio {
  background-color: #0166ff;
  padding: 5px;
  color: #fff;
  font-weight: 600;
  border-radius: 5px;
  width: 100%;
}

.ant-space.dcl-venue-bitmart {
  background-color: #13c2c2;
  padding: 5px;
  color: #fff;
  font-weight: 600;
  border-radius: 5px;
  width: 100%;
}

.ant-space.dcl-venue-okx {
  background-color: #000000;
  padding: 5px;
  color: #fff;
  font-weight: 600;
  border-radius: 5px;
  width: 100%;
}

.ant-space.dcl-venue-lbank {
  background-color: #ffcc66;
  padding: 5px;
  color: #fff;
  font-weight: 600;
  border-radius: 5px;
  width: 100%;
}

.ant-space.dcl-venue-latoken {
  background-color: #5661ff;
  padding: 5px;
  color: #fff;
  font-weight: 600;
  border-radius: 5px;
  width: 100%;
}

.ant-space.dcl-risk-severe {
  background-color: #fbe5e6;
  padding: 5px 10px;
  margin-left: 5px;
  color: #6e191a;
  border: #6e191a 1px solid;
  font-weight: 600;
  border-radius: 5px;
}

.ant-space.dcl-risk-high {
  background-color: #feeae6;
  padding: 5px 10px;
  margin-left: 5px;
  color: #e54a00;
  border: #e54a00 1px solid;
  font-weight: 600;
  border-radius: 5px;
}

.ant-space.dcl-risk-medium {
  background-color: #fff9eb;
  padding: 5px 10px;
  margin-left: 5px;
  color: #715719;
  border: #715719 1px solid;
  font-weight: 600;
  border-radius: 5px;
}

.ant-space.dcl-risk-low {
  background-color: #ceffe7;
  padding: 5px 10px;
  margin-left: 5px;
  border: #6f9f8a 1px solid;
  color: #6f9f8a;
  font-weight: 600;
  border-radius: 5px;
}

.ant-space.dcl-asset-network {
  font-weight: 600;
}

.ant-space.dcl-asset-network-ERC20 {
  color: rgb(0, 190, 253);
}

.ant-space.dcl-asset-network-TRC20 {
  color: rgba(12, 146, 0, 0.801);
}

.ant-space.dcl-N {
  border-radius: 5px;
  font-weight: 600;
  padding: 10px;
}

.ant-space.dcl-Y {
  cursor: pointer;
}

label.ant-radio-button-wrapper.ant-radio-button-wrapper-checked.ant-radio-button-wrapper-in-form-item.dcl-buy {
  background-color: #57be57;
  border-color: #57be57;
}

.ant-radio-button-wrapper:hover.dcl-buy {
  color: #57be57;
}

.dcl-operation-buy {
  color: #57be57;
}

.dcl-operation-sell {
  color: #ed4040;
}

.dcl-operation- {
  color: #ed4040;
}

label.ant-radio-button-wrapper.ant-radio-button-wrapper-checked.ant-radio-button-wrapper-in-form-item.dcl-sell {
  background-color: #ed4040;
  border-color: #ed4040;
}

label.ant-radio-button-wrapper.ant-radio-button-wrapper-in-form-item.dcl-buy {
  border-radius: 5px 0 0 5px;
}

label.ant-radio-button-wrapper.ant-radio-button-wrapper-in-form-item.dcl-sell {
  border-radius: 0 5px 5px 0;
}

.ant-radio-button-wrapper:hover.dcl-sell {
  color: #ed4040;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover:before {
  background-color: #ffbebe;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before {
  background-color: #ffcaca;
}

.ant-space.dcl-Credit,
.ant-space.dcl-buy {
  background-color: #57be57;
  padding: 10px;
  color: #fff;
  font-weight: 600;
  border-radius: 5px;
}

.ant-space.dcl-Deposit {
  background-color: #e5f5f3;
  padding: 5px;
  color: #18aca3;
  font-weight: 600;
  border-radius: 5px;
  border: #18aca3 1px solid;
  min-width: 85px;
}

.ant-space.dcl-Withdrawal {
  background-color: #fff6da;
  padding: 5px;
  color: #d19b1a;
  font-weight: 600;
  border-radius: 5px;
  border: #d19b1a 1px solid;
  min-width: 85px;
}

.ant-space.dcl-Virtual.Deposit {
  background-color: #19bdc2;
  padding: 5px;
  color: #fff;
  font-weight: 600;
  border-radius: 5px;
}

.ant-table-header.ant-table-sticky-holder {
  top: 64px !important;
}

.dcl-filter-row-space {
  margin-left: 1em;
}

.dcl-timezone-selector {
  min-width: 130px;
}

.ant-tabs-content-holder {
  background-color: #fff;
}

.ant-tabs-nav {
  margin-bottom: 0 !important;
}

.ant-pagination {
  padding: 1em 1em;
  margin: 0 !important;
}

.dcl-page-title {
  text-align: center;
  margin: 5px 0 5px 0;
  position: relative;
}

.dcl-helper {
  position: absolute;
  top: 5px;
  right: 10px;
}

.dcl-helper.anticon {
  font-size: 25px;
}

.dcl-helper-content,
.dcl-helper-content p,
.dcl-helper-content h3 {
  color: #155082 !important;
}

.ant-card-grid {
  padding: 10px 25px;
}

.ant-col-sm-2 {
  display: block;
  flex: 0 0 8.333333%;
  max-width: 8.33333333%;
}

.ant-col.ant-col-sm-22 {
  flex: 1;
}

.ant-popover {
  width: 50% !important;
}

i.dcl_state {
  border-radius: 16px;
  display: block;
  float: left;
  height: 16px;
  margin: 2px 5px 0 0;
  width: 16px;
}

i.state_blue {
  color: #d9eef7;
  border: solid 1px #0076a3;
  background: linear-gradient(to left bottom, #4fcfff, #0078a5);
  // background: #0095cd;
  // background: -webkit-gradient(linear, left top, left bottom, from(#00adee), to(#0078a5));
  // background: -moz-linear-gradient(top, #00adee, #0078a5);
  // filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00adee', endColorstr='#0078a5');
}

i.state_red {
  color: #faddde;
  border: solid 1px #980c10;
  background: linear-gradient(to left bottom, #ff000a, #aa1317);

  // background: #d81b21;
  // background: -webkit-gradient(linear, left top, left bottom, from(#ed1c24), to(#aa1317));
  // background: -moz-linear-gradient(top, #ed1c24, #aa1317);
  // filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ed1c24', endColorstr='#aa1317');;
}

i.state_green {
  color: #e8f0de;
  border: solid 1px #538312;
  background: linear-gradient(to left bottom, #a5f539, #4e7d0e);
  // background: #64991e;
  // background: -webkit-gradient(linear, left top, left bottom, from(#7db72f), to(#4e7d0e));
  // background: -moz-linear-gradient(top, #7db72f, #4e7d0e);
  // filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#7db72f', endColorstr='#4e7d0e');
}

i.state_orange {
  color: #fef4e9;
  border: solid 1px #da7c0c;
  background: linear-gradient(to left bottom, #faa51a, #e05f00);
  // background: #f78d1d;
  // background: -webkit-gradient(linear, left top, left bottom, from(#faa51a), to(#f47a20));
  // background: -moz-linear-gradient(top, #faa51a, #f47a20);
  // filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#faa51a', endColorstr='#f47a20');
}

#net-fees {
  width: 100%;
  border-radius: 10px;
  margin: 0 auto;
  box-shadow: 10px 10px 5px #ddd, 0px 0px 5px #ddd;
  -moz-box-shadow: 10px 10px 5px #ddd, 0px 0px 5px #ddd;
  -webkit-box-shadow: 10px 10px 5px #ddd, 0px 0px 5px #ddd;
  -o-box-shadow: 10px 10px 5px #ddd, 0px 0px 5px #ddd;
}

.ebanking-modal-label {
  color: #001529;
}

#rc-tabs-0-panel-order-builder b {
  font-weight: 500;
}

#order-builder-form .calculated,
#edit-order-builer .calculated {
  font-size: larger;
  font-weight: bold;
  padding: 0 0 2px;
}

b.dcl-label-activated {
  color: green;
}

b.dcl-label-archived {
  color: #ed4040;
}

.ant-form-item.dcl-switch {
  margin-bottom: 0px;
}

.ebanking-ledger .ant-modal-content {
  background-color: #e5f1fb;
  border: 2px solid #0e3654;
}

.ebanking-ledger .ant-modal-footer {
  background-color: #e5f1fb;
}

.ebanking-ledger .ebanking-modal-label {
  color: #001529;
}

.ebanking-ledger .ant-col {
  color: #0e3654;
}

.ebanking-ledger .ant-modal-body {
  color: #0e3654 !important;
}

.ebanking-ledger .ant-modal-header {
  background-color: #e5f1fb;
}

#venue-manual-upload .ant-alert.ant-alert-error {
  width: 50%;
  margin: 10px auto 30px;
  border-radius: 5px;
}

#venue-manual-upload .ant-alert.ant-alert-error .ant-alert-message {
  text-align: center;
  font-weight: 500;
}

#venue-manual-upload .ant-alert.ant-alert-warning {
  width: 50%;
  margin: 10px auto 30px;
  border-radius: 5px;
}

#venue-manual-upload .ant-alert.ant-alert-warning .ant-alert-message {
  text-align: center;
  font-weight: 500;
}

.error-icon svg {
  color: #ff4d4f;
}

.warning-icon svg {
  color: #faad14;
}

.dcl-card-display-header {
  text-align: center;
}

#client-info .ant-divider.ant-divider-horizontal {
  margin: 10px 0;
}

#client-info .client-info-wallet-row,
.client-info-sub-account-row {
  padding: 10px 0;
}

#create-asset-page .create-asset-dictionary-space {
  margin: 5px;
}

#edit-asset-page .edit-asset-dictionary-space {
  margin: 5px;
}

#Update-fee-policy .ant-form-item-control-input {
  min-width: 80px;
}

#custody-fee-report {
  padding: 10px;
}

.ant-modal-close-icon svg {
  color: #19acb3;
}

input.ant-input-number-input:disabled {
  color: #0015298c;
}

#custody-fee-report .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner:after {
  border-color: rgba(0, 21, 41, 0.549);
}

#view-venue-ledger .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner:after {
  border-color: rgba(0, 21, 41, 0.549);
}

#view-ebanking-balance .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner:after {
  border-color: rgba(0, 21, 41, 0.549);
}

#custody-fee-report .table-summary-cell-warning {
  border-radius: 10px;
  background-color: orange;
  text-align: center;
}

#view-venue-balance-exposure .state_green .ant-card-head {
  background-color: lightgreen;
  border-radius: 10px 10px 0 0;
}

#view-venue-balance-exposure .ant-card-head-title {
  padding: 10px 0 8px 0;
}

#view-venue-balance-exposure .state_red .ant-card-head {
  background-color: lightcoral;
  border-radius: 10px 10px 0 0;
}

#view-venue-balance-exposure .ant-card.ant-card-bordered {
  font-size: 12px;
}

#venue-exposure-limit label {
  display: block;
  min-width: 100px;
  text-align: left;
}

#bo-dashboard .ant-card-grid {
  padding: 10px 0px 10px 8px;
}

#view-venue-balance-exposure .ant-card-grid.ant-card-grid-hoverable {
  padding: 6px 10px 6px 6px !important;
}

#view-ebanking-balance .ebanking-table-value-not-match {
  background-color: lightcoral;
}

i.dcl-info {
  margin: 0 5px;
  padding: 0px 7px;
  border: 1px solid;
  border-radius: 10px;
  color: #001529;
  cursor: pointer;
}

#venue-trading-report-dashboard .ant-row,
#venue-trading-report-breakdown .ant-row {
  padding-top: 1em;
}

#custody-fee-report .ant-row:first-child {
  padding: 0 10px;
}

#bo-dashboard .ant-card-extra {
  font-size: 18px;
  color: #002c55;
  padding: 0 8px;
  font-weight: normal;
}

.dcl-daterange-select,
.dcl-date-select {
  width: 100%;
}

.dcl-toggled-content-filter .dcl-venue-select,
.dcl-toggled-content-filter .dcl-wallet-type-select,
.dcl-toggled-content-filter .dcl-client-account-select,
.dcl-toggled-content-filter .dcl-client-select,
.dcl-toggled-content-filter .dcl-portfolio-select,
.dcl-toggled-content-filter .dcl-log-action-select {
  min-width: 200px;
}

.dcl-toggled-content-filter .dcl-asset-select,
.dcl-toggled-content-filter .dcl-transaction-way-select,
.dcl-toggled-content-filter .dcl-order-type-select,
.dcl-toggled-content-filter .dcl-asset-type-select,
.dcl-toggled-content-filter .dcl-venue-order-type-select,
.dcl-toggled-content-filter .dcl-order-status-select,
.dcl-toggled-content-filter .dcl-status-select {
  min-width: 150px;
}

.dcl-toggled-content-filter .dcl-date-select {
  width: 200px;
}

#download-telegram-backup li.ant-list-item:nth-child(2n + 1) {
  background-color: #fff;
  border-top: 1px solid #19acb3;
  height: 36px;
}

#download-telegram-backup li.ant-list-item:nth-child(2n + 2) {
  background-color: #19acb32e;
  height: 36px;
}

#view-venue-market-basis b.long-basis {
  color: green;
  font-size: 18px;
}

#view-venue-market-basis b.short-basis {
  color: red;
  font-size: 18px;
}

/* Tooltip container */
.dashboard-tree-svg .tooltip {
  color: white;
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
  /* If you want dots under the hoverable text */
}

/* Tooltip text */
.dashboard-tree-svg .tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: rgba(255, 255, 255, 0.831372549);
  color: #001529;
  text-align: center;
  padding: 5px 0;
  border-radius: 5px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.dashboard-tree-svg .tooltip:hover .tooltiptext {
  visibility: visible;
}

/* Optional: Add some padding to the cells for better visual separation */
#view-kraken-fees #row-volume-data-table .ant-table-tbody>tr:nth-last-child(-n + 15)>td {
  background-color: lightgray;
}

#view-kraken-fees #row-volume-data-table .ant-table-tbody>tr:nth-last-child(-n + 15)>td:nth-child(2) {
  background-color: #19acb3;
  /* Background color for the second column */
  color: white;
  /* Text color for better visibility */
}

#view-kraken-fees .ant-card-grid.ant-card-grid-hoverable {
  padding: 10px 14px;
}

.new-version-alert {
  font-size: 14px;
  text-align: center;
  line-height: 2em;
  background: none;
  border: 2px solid #fff;
  border-radius: 5px;
}

.new-version-alert b {
  color: white;
}

.expanded-menu-item-row {
  margin-bottom: 5px;
}

#menu-settings tr.ant-table-expanded-row:hover>td,
tr.ant-table-expanded-row>td {
  background-color: #e5f1fb;
}

#real-time-venue-price-table {
  @keyframes fadeIn {
    from {
      opacity: 0.3;
      color: #19bdc236;
    }

    to {
      opacity: 1;
    }
  }

  .fade-in {
    animation: fadeIn 1s ease-out;
  }

  & {
    margin-right: 1em;
  }
}

.telegram-input-detail {
  margin-top: 0.5em;
}

.ant-step-button {
  border-radius: 20px !important;
  margin-top: 0px;
}

// Temporary solution on Range picker
// Reference: https://github.com/ant-design/ant-design/issues/26758
.ant-picker-dropdown .ant-picker-panel-container .ant-picker-panels {
  display: inline-flex;
  animation: none;
  flex-wrap: nowrap;
  direction: ltr;

  @media (max-width: 768px) {
    display: inline-grid;
  }
}

.ant-picker-dropdown .ant-picker-panel-container {
  @media (max-width: 768px) {
    margin: 0 0 200px auto;
  }
}

.ant-menu-item-divider {
  // border-color: rgba(1, 114, 220, 0.345) !important;
  border: rgba(0, 128, 247, 0.119) 2px solid !important;
}

.ant-picker-range {
  width: 100%;
  /* Adjust this value as needed */
}

.cutody-report-export-row {
  margin-top: 0.8em;
}

@media (max-width: 768px) {
  .ant-picker-datetime-panel {
    flex-direction: column;
    /* Stack date and time panels vertically */
  }

  .ant-picker-date-panel,
  .ant-picker-time-panel {
    width: 100%;
    /* Ensure each panel takes full width */
  }
}

.ant-btn-dangerous svg {
  color: red;
}

.custom-popover {
  width: 70vw !important;
  /* default for mobile */
}

@media (min-width: 768px) {

  /* breakpoint for desktop */
  .custom-popover {
    width: 30vw !important;
  }
}

.modal-form-label {
  color: white;
}

.creat-subaccount-transfer-modal {
  background-color: #fff;
  border: 1px solid #fff;
}

table tr:nth-child(2n) .ant-table-column-sort,
table tr:nth-child(2n) td,
table tr:nth-child(2n) .ant-table-cell-fix-right,
table tr:nth-child(2n) .ant-table-cell-fix-left {
  background-color: #19bdc20a;
}

.operation-even td {
  background-color: #19bdc20a !important;
}

.operation-odd td {
  background-color: white !important;
}

#view-operation-details td.ant-table-cell {
  padding: 5px 10px;
}

#dcl-balances table tr .ant-table-cell::before,
#view-asset-price table tr .ant-table-cell::before,
#view-users table tr .ant-table-cell::before,
#view-clients table tr .ant-table-cell::before,
#view-venue-accounts table tr .ant-table-cell::before,
#view-venue-historical-market-basis table tr .ant-table-cell::before,
#view-venue-market-prices table tr .ant-table-cell::before,
#view-venue-market-trades table tr .ant-table-cell::before,
#view-venue-order table tr .ant-table-cell::before,
#view-venue-trade table tr .ant-table-cell::before,
#view-venue-ledger table tr .ant-table-cell::before,
#view-venue-deposit-address table tr .ant-table-cell::before,
#view-wallets table tr .ant-table-cell::before,
#venue-trading-report-breakdown table tr .ant-table-cell::before,
#venue-trading-report-currency-breakdown table tr .ant-table-cell::before,
#client-closed-orders table tr .ant-table-cell::before,
#master-ledger-exchange-orders table tr .ant-table-cell::before,
#master-ledger-ebanking table tr .ant-table-cell::before,
#client-open-orders table tr .ant-table-cell::before,
#order-builder table tr .ant-table-cell::before,
#ebanking-ledger table tr .ant-table-cell::before,
#subaccount-transfer-tabs table tr .ant-table-cell::before {
  position: absolute;
  top: 50%;
  inset-inline-end: 0;
  width: 1px;
  height: 1.6em;
  background-color: #e7e7e7;
  transform: translateY(-50%);
  transition: background-color 0.2s;
  content: "";
}

path {
  color: #19bdc2;
}

#navbar-menu path,
.ant-btn-primary path,
.ant-menu-item-selected path,
.Toastify__toast-body path {
  color: #fff;
}

#view-venue-order .icon-button,
#view-venue-trade .icon-button,
#view-venue-ledger .icon-button {
  border: none;
  background: none;
  box-shadow: none;
  font-size: 14px;
  height: 14px;
  padding: 0px 0px;
}

.some-open path {
  color: #fcaf18;
}

.closed path {
  color: #001628;
}

.delete-icon path {
  color: red;
}

.white-plus path {
  color: white;
}

:disabled path {
  color: lightgray;
}

a {
  color: #19bdc2;
}

a:hover {
  color: #158d91;
}

a:disabled {
  color: lightgray;
}

#view-loan-details .ant-form-item {
  margin-bottom: 0px;
}

#view-operation-list svg,
#view-operation-detail svg,
#view-operation-details svg {
  font-size: 1.2em;
}

.terminated-label {
  transform: rotate(30deg);
  background: #03ff0066;
  width: 165px;
  text-align: center;
  position: absolute;
  font-size: 1.5em;
  padding: 0px;
  top: 15%;
  left: 75%;
}

/* Add this to your CSS file */
.ant-table-column-sorter-up.active path,
.ant-table-column-sorter-down.active path,
.ant-table-column-sorter-up path,
.ant-table-column-sorter-down path {
  color: inherit !important;
}

.dsp-button-usdt {
  background-color: #50a98f;
  color: white;
  width: 4em;
}

.dsp-button-usdc {
  background-color: #296ec1;
  color: white;
  width: 4em;
}

.dsp-button-btc {
  background-color: #ef8e19;
  color: white;
  width: 4em;
}

.dsp-button-eth {
  background-color: #ceb0fa;
  color: white;
  width: 4em;
}

.dcl-order-draft,
.dcl-order-draft path {
  color: orange;
}

.dcl-order-canceled,
.dcl-order-canceled path {
  color: red;
}

.dcl-order-executed,
.dcl-order-executed path {
  color: green;
}

.dcl-risk-info {
  border: 1px solid;
  border-radius: 20px;
  color: #fff;
  cursor: pointer;
  font-style: normal;
  margin: 0 5px;
  height: 32px;
  width: 32px;
  display: block;
  text-align: center;
  line-height: 30px;
  font-weight: bold;
}

.dcl-risk-info-lg {
  border: 1px solid;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-style: normal;
  margin: 0 5px;
  height: 32px;
  width: 80px;
  display: block;
  text-align: center;
  line-height: 30px;
  font-weight: bold;
}

.dcl-risk-No {
  background-color: lightgrey;
  border-color: lightgrey;
}

.dcl-risk-Low {
  background-color: #51d80c;
  border-color: #51d80c
}

.dcl-risk-Medium {
  background-color: #f79929;
  border-color: #f79929;
}

.dcl-risk-High {
  background-color: #ff5301;
  border-color: #ff5301
}

.dcl-risk-Severe {
  background-color: #ff0101;
  border-color: #ff0101;
}