body {
  background-color: #f0f2f5;
}

.form-body {
  background-color: white;
  margin: 2em 2em;
  padding: 1em 1em;
  border-radius: 5px;
  width: 85%;
}

.submit-button {
  &:active,
  &:visited {
    background-color: #19acb3 !important;
    color: white !important;
    position: relative;
    top: 1px;
  }
  &:hover {
    background-color: #22d3dc !important;
    border: 1px solid #22d3dc !important;
  }
}
