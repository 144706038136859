.y-axis text {
  font-size: 16px;
  /* Adjust the font size as needed */
}

.x-axis text {
  font-size: 16px;
  /* Adjust the font size as needed */
}

/* Rotate X-axis labels vertically */
.x-axis-label {
  transform: rotate(-45deg);
  text-anchor: end;
}

// .bar {
//   fill: #19bdc2;
// }

/* Tooltip styles */
.tooltip-container {
  background: #ffffffd4;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #001529;
  color: #001529;
  text-align: left;
  height: 50px; /* Set a fixed height */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
