@use "sass:color";
$primary-btn-bgColor: #19acb3;
.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.search-filter-form {
  padding: 24px;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  margin-bottom: 30px;
  & .ant-col-8 {
    height: 40px;
  }
}

.search-filter-form .ant-form-item {
  display: flex;
}

.search-filter-form .ant-form-item-control-wrapper {
  flex: 1;
}
.ant-form-item-label {
  text-align: left;
}

.submit-button {
  width: 30%;
  background-color: $primary-btn-bgColor !important;
  border: 2px solid $primary-btn-bgColor !important;
  border-radius: 5px;
  color: white !important;
  outline: none;
  font-size: 17px;
  font-weight: 500;
  padding: 10px;
  box-sizing: border-box;
  height: auto !important;
  margin-top: 10px;
  margin-bottom: 10px;
}

.ant-btn {
  margin: 5px;
  // border-radius: 5px !important;
}
.ant-btn-primary {
  background-color: $primary-btn-bgColor !important;
  border-color: $primary-btn-bgColor !important;
}

.ant-btn-primary:hover {
  background-color: color.adjust(
    $primary-btn-bgColor,
    $lightness: 10%
  ) !important;
  border: 1px solid color.adjust($primary-btn-bgColor, $lightness: 10%) !important;
}

.ant-btn-primary:focus {
  border-color: $primary-btn-bgColor;
}

/** Dropdown button
*/
.dcl-select-dropdown {
  border-radius: 5px !important;
}
// .ant-col {
//   border: 1px solid black;
// }
